import React from "react";
import PropTypes from "prop-types";

const Home = ({ BASEURL }) => {
  return (
    <div className="">
      <div className="container-fluid backcover">
        <div className="">
          <div className="text-center registerLogo">
            <div>
              <a href="/home">
                <img
                  src="/logo.png"
                  alt="Cadence"
                  style={{ height: "300px", verticalAlign: "center" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="homepageBanner pb-3">
          <div className="row pb-2">
            <div className="col-lg-6 offset-lg-3 text-center px-4 align-center ">
              <img
                src="/scan.png"
                alt="Cadence"
                className="shadow2"
                style={{
                  height: "150px",
                  verticalAlign: "center",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              />
              <h2 className="pt-5 text-white">
                <span className=" textshadow">
                  Empowering Doctors, Elevating Care, One Click at a Time with{" "}
                </span>
                <b
                  style={{ fontSize: "40px" }}
                  className="text-primary-color pt-3 w3-block"
                >
                  Squad4Docs
                </b>
                <span></span>
              </h2>

              <br />

              <div className="row   pb-3">
                <div className="col-lg-6 pt-3">
                  <a
                    href="https://rb.gy/d7d57o"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-grad w3-block"
                    style={{
                      borderRadius: "30px",
                    }}
                  >
                    Reserve an appointment
                  </a>
                </div>
                <div className="col-lg-6 pt-3">
                  <a
                    href="#learnmore"
                    className="btn btn-grad w3-block"
                    style={{
                      borderRadius: "30px",
                    }}
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid  px-4 pt-2 "
        style={{
          minHeight: "500px",
          position: "relative",
          zIndex: 3,
        }}
      >
        <div className="pt-5 pb-5  ">
          <div className="row">
            <div className="col-lg-6 ">
              <div
                className="card  mb-3 shadow2"
                style={{ borderRadius: "15px" }}
              >
                <div calsclassName="card-header">
                  <h3 className="p-4">
                    <b
                      style={{ fontSize: "30px" }}
                      className="text-primary-color"
                    >
                      Squad4Docs
                    </b>{" "}
                    is a <span className="text-primary-color">powerful</span>{" "}
                    online tool
                    <br /> (website) that:
                  </h3>
                </div>
                <div className="card-body">
                  <div style={{ fontWeight: "300" }}>
                    <ul className="custom-bullets">
                      <li className="pb-3">
                        {" "}
                        Helps patients get to know YOU!{" "}
                      </li>
                      <li className="pb-3"> Shows off YOUR services!</li>
                      <li className="pb-3">
                        Attracts patients by providing useful information!
                      </li>
                      <li className="pb-3">
                        Shares YOUR good reviews so patients are more likely to
                        see YOU!
                      </li>
                      <li className="pb-3">
                        {" "}
                        Encourages prospective patients to TAKE ACTION!
                      </li>
                      <li className="pb-3"> Makes YOU look GOOD!</li>
                      <li className="pb-3">
                        {" "}
                        Helps patients FIND YOUR PRACTICE!
                      </li>
                      <li className="pb-3"> PERFORMS...FAST!</li>
                      <li className="pb-3">
                        Makes it easy for patients to find your practice in any
                        search engine!
                      </li>
                      <li className="pb-3"> Is industry compliant!</li>
                      <li className="pb-3">
                        {" "}
                        Shows YOUR brand personality and builds trust!
                      </li>
                      <li className="pb-3">
                        {" "}
                        Empowers patients to choose YOU!
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6    text-center align-center ">
              <div
                className=" shadow2 py-5  "
                style={{
                  borderRadius: "15px",

                  backgroundColor: "#112e5d",
                }}
              >
                <img
                  src="/dockey.png"
                  alt="Cadence"
                  className="  "
                  style={{
                    width: "250px",
                    verticalAlign: "center",
                  }}
                />
                <br />
                <img
                  src="/getin.png"
                  alt="Cadence"
                  className=""
                  style={{
                    width: "560px",
                    maxWidth: "100%",
                    verticalAlign: "center",
                  }}
                />
                <div className="py-4">
                  <h4 className="text-white" style={{ fontWeight: "600" }}>
                    <span className="text-primary-color">E :</span>
                    <a
                      href="mailto:info@squadinventive.us"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@squadinventive.us
                    </a>
                  </h4>

                  <h4 className="text-white pt-3" style={{ fontWeight: "600" }}>
                    <span className="text-primary-color">W :</span>
                    <a
                      href="https://squadinventive.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.squadinventive.com
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid  packages  px-4 pt-5 "
        id="learnmore"
        style={{
          minHeight: "500px",
          position: "relative",
          zIndex: 3,
        }}
      >
        <h3
          className="text-center text-primary-color py-3 mt-5"
          style={{ position: "relative", zIndex: 3 }}
        >
          <b>PACKAGES</b>
          <br />
        </h3>
        <div className="pt-5 pb-5  ">
          <div className="row">
            {/* =================================================== BASIC ============================================== */}
            <div className="col-lg-3">
              <div
                className="card  mb-3 shadow"
                style={{ borderRadius: "25px", overflow: "hidden" }}
              >
                <div
                  className="card-body p-0 border-0 w3-white"
                  style={{ overflow: "hidden" }}
                >
                  <div className="bg-secondary">
                    <div className="p-3">
                      <h4 className="w3-text-white py-4  text-center">
                        <b>BASIC</b>
                      </h4>
                      <div className="text-info px-4 text-center">
                        Ideal for small practices or individual physicians
                        starting out online
                      </div>
                    </div>
                    <img
                      src="/pack.png"
                      alt="basic"
                      style={{
                        width: "101%",
                        marginLeft: "-1px",
                        marginTop: "-30px",
                      }}
                    />
                    <h4
                      className="text-primary-color pb-4  w3-white text-center"
                      style={{ margin: "-1px", fontWeight: "900" }}
                    >
                      <b> $1,200 - $1700</b>
                    </h4>
                  </div>
                  <div className="p-4">
                    <div>
                      <b className="text-secondary-color">
                        BASIC DESIGN TIER - single landing page to up to 5
                        mobile friendly pages ($700-1000)
                      </b>{" "}
                    </div>
                    <br />
                    <div style={{ fontWeight: "300" }}>
                      <ul className="custom-bullets">
                        <li className="pb-3">“Get To Know The Doctor”</li>
                        <li className="pb-3"> -Ways to Schedule Appointment</li>
                        <li className="pb-3">
                          -Interactive Maps and Location Features -Clear CTA
                          Button -
                        </li>
                        <li className="pb-3">
                          -On-Brand High-Quality Design -Health Information
                          Resources
                        </li>
                        <li className="pb-3">
                          -Insurance Protocols and Financial Policies
                        </li>
                        <li className="pb-3">
                          {" "}
                          -ADA Accessibility & HIPAA Compliant
                        </li>
                        <li className="pb-3">
                          {" "}
                          -Ensures the website is accessible to people with
                          disabilities
                        </li>
                      </ul>
                      <div>
                        <b className="text-primary-color">Basic SEO Features</b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - On-page SEO (basic keyword optimization, meta tags)
                        </li>

                        <li className="pb-3">
                          {" "}
                          - Submission to Google My Business
                        </li>
                      </ul>

                      <div>
                        <b className="text-primary-color">Mobile Friendly</b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - Optimized for mobile devices Fast
                        </li>

                        <li className="pb-3">
                          {" "}
                          Hosting Reliable and fast hosting service
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            {/* =================================================== STANDARD ============================================== */}
            <div className="col-lg-3">
              <div
                className="card  mb-3 shadow"
                style={{ borderRadius: "25px", overflow: "hidden" }}
              >
                <div
                  className="card-body p-0 border-0 w3-white"
                  style={{ overflow: "hidden" }}
                >
                  <div className="bg-secondary">
                    <div className="p-3">
                      <h4 className="w3-text-white py-4  text-center">
                        <b>STANDARD</b>
                      </h4>
                      <div className="text-info px-4 text-center">
                        Perfect for growing prac- tices wanting to enhance
                        patient engagement
                      </div>
                    </div>
                    <img
                      src="/pack.png"
                      alt="basic"
                      style={{
                        width: "101%",
                        marginLeft: "-1px",
                        marginTop: "-30px",
                      }}
                    />
                    <h4
                      className="text-primary-color pb-4  w3-white text-center"
                      style={{ margin: "-1px", fontWeight: "900" }}
                    >
                      <b>$2000 - $3500</b>
                    </h4>
                  </div>
                  <div className="p-4">
                    <div>
                      <b className="text-secondary-color">
                        All features from the Basic Package STANDARD DESIGN TIER
                        - 6-10 mobile friendly pages, customized icons, branding
                        colors, static page, Simpler Graphics and Images, 1
                        month free support;
                        <br />
                        (Price: $1100 - $1500)
                      </b>{" "}
                    </div>
                    <br />
                    <div>
                      <b className="text-primary-color">
                        Enhanced SEO Features - 6 mos service
                      </b>
                    </div>
                    <div style={{ fontWeight: "300" }}>
                      <ul className="custom-bullets">
                        <li className="pb-3">-On-page and off-page SEO</li>
                        <li className="pb-3">
                          {" "}
                          -Submission to Google My Business
                        </li>
                        <li className="pb-3">-Local SEO optimization</li>
                        <li className="pb-3">-Monthly performance reports</li>
                      </ul>
                      <div>
                        <b className="text-primary-color"> Mobile Friendly</b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">- Optimized for mobile devices</li>
                      </ul>
                      <div>
                        <b className="text-primary-color">Fast Hosting</b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          -Reliable and fast hosting service
                        </li>
                      </ul>

                      <div>
                        <b className="text-primary-color">
                          Testimonials and Reviews
                        </b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - Positive reviews and testimonials
                        </li>

                        <li className="pb-3">
                          - Link to Google Business Page and Reviews
                        </li>
                      </ul>
                      <div>
                        <b className="text-primary-color">Online Bill Pay</b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          -Simple and secure online payment system
                        </li>
                      </ul>
                      <div>
                        <b className="text-primary-color">
                          Patient Portal Access
                        </b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - Secure access to patient information, including
                          medical records and appointment scheduling
                        </li>
                      </ul>
                      <div>
                        <b className="text-primary-color">
                          Marketing and Branding Features
                        </b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">- Blogs</li>
                        <li className="pb-3">- Social media integration</li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            {/* =================================================== DELUXE ============================================== */}
            <div className="col-lg-3">
              <div
                className="card  mb-3 shadow"
                style={{ borderRadius: "25px", overflow: "hidden" }}
              >
                <div
                  className="card-body p-0 border-0 w3-white"
                  style={{ overflow: "hidden" }}
                >
                  <div className="bg-secondary">
                    <div className="p-3">
                      <h4 className="w3-text-white py-4  text-center">
                        <b>DELUXE</b>
                      </h4>
                      <div className="text-info px-4 text-center">
                        Designed for established practices aiming for a
                        comprehensive online presence
                      </div>
                    </div>
                    <img
                      src="/pack.png"
                      alt="basic"
                      style={{
                        width: "101%",
                        marginLeft: "-1px",
                        marginTop: "-30px",
                      }}
                    />
                    <h4
                      className="text-primary-color pb-4  w3-white text-center"
                      style={{ margin: "-1px", fontWeight: "900" }}
                    >
                      <b>$4,000 - $5,500</b>
                    </h4>
                  </div>
                  <div className="p-4">
                    {" "}
                    <div className="pb-3">
                      <b className="text-secondary-color">
                        All features from the Standard Package
                      </b>{" "}
                    </div>
                    <div>
                      <b className="text-secondary-color">
                        DELUXE DESIGN TIER - 10+ mobile friendly pages,
                        customized icons, branding colors, dynamic or static
                        page, Custom Graphics and Images, 2 month free s
                      </b>{" "}
                    </div>
                    <br />
                    <div className="">
                      <b className="text-primary-color">
                        Interactive Multimedia Content
                      </b>{" "}
                    </div>
                    <div style={{ fontWeight: "300" }}>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - Advanced interactive features (e.g., virtual tours)
                        </li>
                        <li className="pb-3">
                          {" "}
                          - High-quality multimedia content (videos, webinars,
                          podcasts)
                        </li>
                      </ul>
                      <div>
                        <b className="text-primary-color">
                          Advanced SEO Features (4 mos service)
                        </b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">- Comprehensive SEO strategy</li>

                        <li className="pb-3"> - Competitive analysis</li>
                        <li className="pb-3"> - Backlink building</li>
                        <li className="pb-3">- Detailed monthly SEO reports</li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
            {/* =================================================== ULTIMATE ============================================== */}
            <div className="col-lg-3">
              <div
                className="card  mb-3 shadow"
                style={{ borderRadius: "25px", overflow: "hidden" }}
              >
                <div
                  className="card-body p-0 border-0 w3-white"
                  style={{ overflow: "hidden" }}
                >
                  <div className="bg-secondary">
                    <div className="p-3">
                      <h4 className="w3-text-white py-4  text-center">
                        <b>ULTIMATE</b>
                      </h4>
                      <div className="text-info px-4 text-center">
                        The complete package for high-demand practices requiring
                        top-tier security, performance, and customization
                      </div>
                    </div>
                    <img
                      src="/pack.png"
                      alt="basic"
                      style={{
                        width: "101%",
                        marginLeft: "-1px",
                        marginTop: "-30px",
                      }}
                    />
                    <h4
                      className="text-primary-color pb-4  w3-white text-center"
                      style={{ margin: "-1px", fontWeight: "900" }}
                    >
                      <b>$6,000 - $8,000</b>
                    </h4>
                  </div>
                  <div className="p-4">
                    <div>
                      <b className="text-secondary-color">
                        All features from the Deluxe Package
                      </b>{" "}
                    </div>
                    <br />
                    <div>
                      <b className="text-primary-color">
                        Advanced Security Features
                      </b>{" "}
                    </div>
                    <div style={{ fontWeight: "300" }}>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - Secure Login and Two-factor Authentication
                        </li>
                        <li className="pb-3"> - Regular Backups and Updates</li>
                        <li className="pb-3">
                          - Encryption and audit controls
                        </li>
                      </ul>
                      <div>
                        <b className="text-primary-color">
                          Custom Design and Branding
                        </b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - Tailored web design to reflect the practice`s unique
                          brand
                        </li>

                        <li className="pb-3">
                          - Custom graphics and interactive elements
                        </li>
                        <li className="pb-3">
                          - Personalized patient engagement tools
                        </li>
                      </ul>

                      <div>
                        <b className="text-primary-color">
                          Full Marketing Suite- 6 mos of service
                        </b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - Comprehensive digital marketing services
                        </li>

                        <li className="pb-3">
                          - SEO, PPC, and content marketing
                        </li>
                        <li className="pb-3">
                          - Detailed analytics and performance tracking{" "}
                        </li>
                      </ul>
                      <div>
                        <b className="text-primary-color">
                          Ultimate SEO Features- 6 mos of service
                        </b>{" "}
                      </div>
                      <ul className="custom-bullets">
                        <li className="pb-3">
                          - Full SEO audit and continuous optimization
                        </li>

                        <li className="pb-3">
                          - Content creation and marketing
                        </li>
                        <li className="pb-3">
                          - High-quality backlink building
                        </li>
                        <li className="pb-3">
                          - Advanced analytics and custom reports
                        </li>
                        <li className="pb-3">- Voice search optimization</li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="  bg-secondary  px-4  py-4 text-center "
        style={{ position: "relative", zIndex: "3" }}
      >
        <h4 className="text-primary-color pt-3" style={{ fontWeight: "600" }}>
          <a href="https://squadinventive.com" target="_blank" rel="noreferrer">
            www.squadinventive.com <ion-icon name="planet-outline"></ion-icon>
          </a>
        </h4>
      </div>
    </div>
  );
};

Home.propTypes = {
  BASEURL: PropTypes.string,
};

export default Home;
